import React, { Component } from 'react'
import Plans from '../Plans'

class PlansSection extends Component {
    render() {
        return(
            <section className="container section section-plans js--section-plans" id="plans">
                <div className="row">
                    <h2 className="h2">Start eating healthy today</h2>
                </div>
                <div className="row plan-detail">
                    <Plans
                        name="Premium"
                        price="8,999"
                        duration="mo"
                        use="Best suited for students, bachelors"
                        rate="That's only ₹99 per meal"
                        mealsPerDay="3 meals everyday"
                        operatingHours="24/7 Delivery"
                        latestItems="Access to newest creations"
                        delivery="Free Delivery"
                        buttonHighlight="btn-ghost-plan"
                        status="Coming Soon!"
                        planDetails="/"
                    />
                    <Plans
                        name="Basic"
                        price="4,499"
                        duration="mo"
                        use="Best suited for working individuals"
                        rate="That's only ₹149 per meal"
                        mealsPerDay="1 meal everyday"
                        operatingHours="24/7 Delivery"
                        latestItems="Access to newest creations"
                        delivery="Free Delivery"
                        buttonHighlight="btn-ghost-plan"
                        status="Coming Soon!"
                        planDetails="/"
                    />
                    <Plans
                        name="Custom"
                        price="149"
                        duration="meal"
                        use="Best suited for curing hunger quickly"
                        rate="Super fast delivery"
                        mealsPerDay="1 meal"
                        operatingHours="8AM to 10PM Delivery"
                        latestItems="20 minutes delivery"
                        delivery="Free Delivery"
                        buttonHighlight="btn-full"
                        status="Explore!"
                        planDetails="/menu"
                    />
                </div>
            </section>
        )
    }
}

export default PlansSection