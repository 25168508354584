import React, { Component } from "react";

class Works extends Component {
    render(){
        return(
            <div className="works-step clearfix">
                <div>{this.props.stepNo}</div>
                <p>{this.props.step}</p>
            </div>
        )
    }
}

export default Works