import React, { Component } from 'react'
import hero from '../../assets/Hero.webm'
import NavigationBar from '../NavigationBar'
import { Link } from 'gatsby'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        speed: 200,
        offset: 60,
        updateURL: false
    })
}

class Header extends Component {
    render() {
        return(
            <header className="main-header">
                <NavigationBar
                    id1="/#features"
                    id2="/#works"
                    id3="/#cities"
                    id4="/#plans"
                    text1="Food Delivery"
                    text2="Process"
                    text3="Locations"
                    text4="Plans"
                />
                <div className="video-box">
                    <video autoPlay muted loop>
                        <source src={hero} type="video/webm" />
                    </video>
                </div>
                <div className="hero-text-box">
                    <h1 className="h1">Goodbye junk food.<br />Hello super healthy meals.</h1>
                    <Link to="/menu" exact="true" className="btn btn-full-landing">I'm hungry</Link>
                    <Link className="btn btn-ghost" exact="true" to="/#plans">Show me more</Link>
                </div>
            </header>
        )
    }
}

export default Header