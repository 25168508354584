import React, { Component } from 'react'

class Testimonials extends Component {
    render() {
        return(
            <div className="col-md-4">
                <blockquote>
                    {this.props.quote}
                    <cite><img src={this.props.image} alt={this.props.author} />{this.props.author}</cite>
                </blockquote>
            </div>
        )
    }
}

export default Testimonials