import React, { Component } from 'react';
import LazyLoad from 'react-lazyload'

class Locations extends Component {
    render() {
        return(
            <div className="col-lg-3 col-md-6 box">
                <LazyLoad once offset={100}>
                    <img src={this.props.locationImage} alt={this.props.location} />
                </LazyLoad>
                <h3 className="h3">{this.props.location}</h3>
                <div className="city-feature">
                    <i className="ion-ios-person icon-small"></i>
                    {this.props.eaters}+ happy eaters
                </div>
                <div className="city-feature">
                    <i className="ion-ios-star icon-small"></i>
                    {this.props.chefs}+ top chefs
                </div>
                <div className="city-feature">
                    <i className="ion-social-twitter icon-small"></i>
                    <a href="#">@{this.props.twitter}</a>
                </div>
            </div>
        )
    }
}

export default Locations