import React, { Component } from 'react'

class Features extends Component {
    render() {
        return(
            <div className="col-lg-3 col-md-6 box">
                <i className={`${this.props.icon} icon-big`}></i>
                <h3 className="h3">{this.props.heading}</h3>
                <p>{this.props.paragraph}</p>
            </div>
        )
    }
}

export default Features