import React, { Component } from 'react'
import Delhi from '../../assets/delhi.jpg'
import Hyderabad from '../../assets/hyderabad.jpg'
import Kolkata from '../../assets/kolkata.jpg'
import Mumbai from '../../assets/mumbai.jpg'
import Locations from '../Locations'

class LocationsSection extends Component {
    render() {
        return(
            <section className="container section section-cities" id="cities">
                <div className="row">
                    <h2 className="h2">We're currently in these cities</h2>
                </div>
                <div className="row location-detail">
                    <Locations
                        locationImage={Delhi}
                        location="Delhi"
                        eaters="3200"
                        chefs="150"
                        twitter="foodquickie_del"
                    />
                    <Locations
                        locationImage={Hyderabad}
                        location="Hyderabad"
                        eaters="2400"
                        chefs="110"
                        twitter="foodquickie_hyd"
                    />
                    <Locations
                        locationImage={Kolkata}
                        location="Kolkata"
                        eaters="2000"
                        chefs="90"
                        twitter="foodquickie_kol"
                    />
                    <Locations
                        locationImage={Mumbai}
                        location="Mumbai"
                        eaters="4200"
                        chefs="200"
                        twitter="foodquickie_mum"
                    />
                </div>
            </section>
        )
    }
}

export default LocationsSection