import React, { Component } from "react"
import { Helmet } from "react-helmet"
import thumbnail from '../../static/thumbnail.png'
import Header from '../components/Landing/Header'
import FeaturesSection from '../components/Landing/FeaturesSection'
import PromotionsSection from '../components/Landing/PromotionsSection'
import WorksSection from '../components/Landing/WorksSection'
import LocationsSection from '../components/Landing/LocationsSection'
import TestimonialsSection from '../components/Landing/TestimonialsSection'
import PlansSection from '../components/Landing/PlansSection'
import ContactSection from '../components/Landing/ContactSection'
import Footer from '../components/Footer'

class Home extends Component {
  render() {
    return(
      <div>
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
        >
          <meta charSet="utf-8" />
          {/* Robot Meta Tags */}
          <meta name="robots" content="noindex,nofollow,noarchive" />

          {/* HTML Meta Tags */}
          <title>Food Quickie | Home</title>
          <meta name="description" 
          content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />

          {/* Google / Search Engine Tags */}
          <meta itemprop="name" content="Food Quickie | Home" />
          <meta itemprop="description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
          <meta itemprop="image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

          {/* Facebook Meta Tags */}
          <meta property="og:url" content="https://ecommerce.amolgarhwal.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Food Quickie | Home" />
          <meta property="og:description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
          <meta property="og:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Food Quickie | Home" />
          <meta name="twitter:description" content="Welcome to Food Quickie! Enjoy our delicious meals prepared by some of the best chefs in the country, delivered quickly to your doorstep." />
          <meta name="twitter:image" content={`https://ecommerce.amolgarhwal.com${thumbnail}`} />

        </Helmet>
        <Header />
        <FeaturesSection />
        <PromotionsSection />
        <WorksSection />
        <LocationsSection />
        <TestimonialsSection />
        <PlansSection />
        <ContactSection />
        <Footer />
      </div>
    )
  }
}

export default Home;
