import React, { Component } from 'react'
import customer1 from '../../assets/customer-1.jpg'
import customer2 from '../../assets/customer-2.jpg'
import customer3 from '../../assets/customer-3.jpg'
import Testimonials from '../Testimonials'

class TestimonialsSection extends Component {
    render() {
        return(
            <section className="section section-testimonials">
                <div className="row">
                    <h2 className="h2">Our customers can't live without us</h2>
                </div>
                <div className="row testimonial-detail">
                    <Testimonials
                        quote="Food Quickie is just awesome! I just launched a startup which leaves me with no time for cooking, so Food Quickie is a life-saver. Now that I got used to it, I couldn't live without my daily meals!"
                        image={customer1}
                        author="Anjana Sharma"
                    />
                    <Testimonials
                        quote="Inexpensive, healthy and great-tasting meals, delivered right to my home. We have lots of food delivery here in Delhi, but no one comes even close to Food Quickie. Me and my family are in love!"
                        image={customer2}
                        author="Yogesh Singh"
                    />
                    <Testimonials
                        quote="I was looking for a quick and easy food delivery service in Mumbai. I tried a lot of them and ended up with Food Quickie. Best food delivery service in the region. Keep up the great work!"
                        image={customer3}
                        author="Shweta Kumar"
                    />
                </div>
            </section>
        )
    }
}

export default TestimonialsSection