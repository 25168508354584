import React, { Component } from 'react'
import Features from '../Features'

class FeaturesSection extends Component {
    render() {
        return(
            <section className="container section section-features" id="features">
                <div className="row">
                    <h2 className="h2">Get food fast &mdash; not fast food</h2>
                </div>
                <div className="row features-extract">
                    <p className="long-copy">
                        Hello! We're Food Quickie, your new premium food delivery service. We know that you're always busy and have no time for cooking, so let us take care of that. We're really good at it, and are sure that your taste buds will agree!
                    </p>
                </div>
                <div className="row features-detail">
                    <Features
                        icon="ion-ios-infinite-outline"
                        heading="Up to 365 days/year"
                        paragraph="Never cook again! We really mean that. Our subscription plans include up to 365 days/year coverage. You can also choose to order more flexibly if that's your style."
                    />
                    <Features
                        icon="ion-ios-stopwatch-outline"
                        heading="Ready in 20 minutes"
                        paragraph="You're only twenty minutes away from your delicious and super healthy meals, delivered right at your doorstep. We work with the best chefs in each town to ensure that you're 100% satisfied."
                    />
                    <Features
                        icon="ion-ios-nutrition-outline"
                        heading="100% organic"
                        paragraph="All our vegetables are fresh, organic and local. Animals are raised without added hormones or antibiotics. Good for your health, the environment, and it also tastes better!"
                    />
                    <Features
                        icon="ion-ios-cart-outline"
                        heading="Order anything"
                        paragraph="We don't limit your creativity, which means you can order whatever you feel like. You can also choose from our menu containing over 30 delicious meals, with an ever expanding catalogue. It's up to you!"
                    />
                </div>
            </section>
        )
    }
}

export default FeaturesSection