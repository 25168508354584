import React, { Component } from 'react'

class ContactSection extends Component {
    render() {
        return(
            <section className="container section section-form">
                <div className="row">
                    <h2 className="h2">We're happy to hear from you</h2>
                </div>
                <div className="row">
                    <form method="post" action="#" className="contact-form">
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="col-8">
                                <input type="text" name="name" id="name" placeholder="Your name" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="col-8">
                                <input type="email" name="email" id="email" placeholder="Your email" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="find-us">How did you find us?</label>
                            </div>
                            <div className="col-8">
                                <select name="find-us" id="find-us" defaultValue="friends">
                                    <option value="friends">Friends</option>
                                    <option value="search">Search engine</option>
                                    <option value="ad">Advertisement</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="news">Newsletter?</label>
                            </div>
                            <div className="col-8">
                                <input type="checkbox" name="news" id="news" defaultChecked /> Yes, please
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="message">Drop us a line</label>
                            </div>
                            <div className="col-8">
                                <textarea name="message" id="message" placeholder="Your message"></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label>&nbsp;</label>
                            </div>
                            <div className="col-8">
                                <input type="submit" value="Send it!" />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}

export default ContactSection