import React from 'react'
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const PromotionsSection = () => {
  return (
    <StaticQuery
        query={query}
        render={data => (
            <section className="section section-meals">
                <div className="meals-showcase row no-pad full-width">
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img1.childImageSharp.fluid} alt="Butter Chicken" className="meal-photo-img" />
                        </figure>
                    </div>    
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img2.childImageSharp.fluid} alt="Pav Bhaji" className="meal-photo-img" />
                        </figure>
                    </div>    
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img3.childImageSharp.fluid} alt="Idli Sambar" className="meal-photo-img" />
                        </figure>
                    </div> 
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img4.childImageSharp.fluid} alt="Tandoori Chicken" className="meal-photo-img" />
                        </figure>
                    </div>
                </div>
                <div className="meals-showcase row no-pad full-width">
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img5.childImageSharp.fluid} alt="Palak Paneer" className="meal-photo-img" />
                        </figure>
                    </div>
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img6.childImageSharp.fluid} alt="Chicken Biryani" className="meal-photo-img" />
                        </figure>
                    </div> 
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img7.childImageSharp.fluid} alt="Masala Dosa" className="meal-photo-img" />
                        </figure>
                    </div> 
                    <div className="no-pad col-lg-3 col-6">
                        <figure className="meal-photo-div">
                            <Img fluid={data.img8.childImageSharp.fluid} alt="Mutton Rogan Josh" className="meal-photo-img" />
                        </figure>
                    </div>
                </div>
            </section>  
        )}
    />
    );
};

export default PromotionsSection;

const query = graphql`
  query {
    img1: file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "7.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "8.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
