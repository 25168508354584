import React, { Component } from 'react'
import { Link } from 'gatsby';

class Plans extends Component {
    render() {
        return(
            <div className="col-md-4">
                <div className="plan-box js--wp-4">
                    <div>
                        <h3 className="h3">{this.props.name}</h3>
                        <p className="plan-price">₹{this.props.price} <span>/{this.props.duration}</span></p>
                        <p className="plan-price-meal">{this.props.use}</p>
                        <p className="plan-price-meal">{this.props.rate}</p>
                    </div>
                    <div>
                        <ul>
                            <li><i className="ion-ios-checkmark-empty icon-small"></i>{this.props.mealsPerDay}</li>
                            <li><i className="ion-ios-checkmark-empty icon-small"></i>{this.props.operatingHours}</li>
                            <li><i className="ion-ios-checkmark-empty icon-small"></i>{this.props.latestItems}</li>
                            <li><i className="ion-ios-checkmark-empty icon-small"></i>{this.props.delivery}</li>
                        </ul>
                    </div>
                    <div>
                        <Link to={this.props.planDetails} exact="true"><button href="/#" className={`btn-plans ${this.props.buttonHighlight}`} disabled={this.props.buttonHighlight === 'btn-ghost-plan'}>{this.props.status}</button></Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Plans