import React, { Component } from 'react'
import Works from '../Works'
import iPhone from '../../assets/app-iPhone.png'
import appStore from '../../assets/download-app.svg'
import playStore from '../../assets/download-app-android.svg'
import LazyLoad from 'react-lazyload'

class WorksSection extends Component {
    render() {
        return(
            <section className="section section-steps" id="works">
                <div className="row">
                    <h2 className="h2">Process &mdash; Simple as 1, 2, 3</h2>
                </div>
                <div className="row">
                    <div className="col-md-6 steps-box">
                        <LazyLoad once offset={100}>
                            <img src={iPhone} alt="Food Quickie app on iPhone" className="app-screen js--wp-2" />
                        </LazyLoad>
                    </div>
                    <div className="col-md-6 steps-box">
                        <Works
                            stepNo="1"
                            step="Choose the subscription plan that best fits your needs and sign up today."
                        />
                        <Works
                            stepNo="2"
                            step="Order your delicious meal using our mobile app or website. Or you can even call us!"
                        />
                        <Works
                            stepNo="3"
                            step="Enjoy your meal in less than 20 minutes. See you soon!"
                        />
                        <div className="row">
                            <div className="col-6 mobile-button">
                                <a href="#" className="btn-app"><img src={appStore} alt="App Store Button" /></a>
                            </div>
                            <div className="col-6 mobile-button">
                                <a href="#" className="btn-app"><img src={playStore} alt="Play Store Button" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default WorksSection